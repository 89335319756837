<template>
  <div id="contacts-view">


    <contacts-table v-if="showContactTable === true" v-bind:showContactTable="showContactTable"
      v-on:addContact="addContact" v-on:contactEdit="contactEdit"></contacts-table>
    <create-edit v-if="showContactTable === false" v-on:closeContactEdit="closeContactEdit"
      v-bind:contactData="contactData" v-bind:edit="edit"></create-edit>


  </div>
</template>

<script>
    import ContactsTable from '@/views/site/contact/ContactsTable'
    import CreateEdit from '@/views/site/contact/CreateEdit'

    export default {
        name: "ContactsView",

        components: {
            ContactsTable,
            CreateEdit,

        },
        data() {
            return {
                edit: false,
                contactData: [],
                showContactTable: true,
                filter: null,
            };
        },
        methods: {
            addContact() {
                this.showContactTable = false;
                this.edit = false;
                this.contactData = [];
            },
            contactEdit(contact) {
                this.edit = true;
                this.showContactTable = false;
                this.contactData = contact.item;
            },

            closeContactEdit() {
                this.showContactTable = true;
            }
        }
    };
</script>